import type { IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "App:Prompt:Banner:View": {
    ya: {
      eventName: "iconOnDesc_view",
      chapter: "",
    },
  },
  "App:Prompt:Banner:Click": {
    ya: {
      eventName: "iconOnDesc_click",
      block: "iconOnDesc",
      chapter: "",
    },
  },
  "App:Prompt:Banner:Cancel": {
    ya: {
      eventName: "iconOnDesc_back_click",
      block: "iconOnDesc",
      element: "back",
      chapter: "",
    },
  },
  "App:Install:Banner:View": {
    ya: {
      eventName: "successInstall_view",
      block: "successInstall",
      chapter: "",
    },
  },
  "Page:Error404:View": {
    ya: {
      eventName: "404error",
    },
  },
};
