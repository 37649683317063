import type { IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";
import eventsMyCard from "./myCard";
import eventsSettings from "./settings";
import eventsLogin from "./login";
import eventsLanding from "./landing";
import eventsRegistration from "./registration";
import eventsMain from "./main";
import eventsNavbar from "./navbar";
import eventsApp from "./app";
import eventsSubscription from "./subscription";

export default <IAnalyticEventsConfig>{
  ...eventsNavbar,
  ...eventsApp,
  ...eventsLanding,
  ...eventsLogin,
  ...eventsRegistration,
  ...eventsMain,
  ...eventsSettings,
  ...eventsMyCard,
  ...eventsSubscription,
};
