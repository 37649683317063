import type { IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "MySettings:Info:Call:Click": {
    ya: {
      eventName: "sett_setMain_info_call_click",
      chapter: "sett",
      page: "setMain",
      block: "info",
      element: "call",
    },
  },
  "MySettings:Info:Email:Click": {
    ya: {
      eventName: "sett_setMain_info_email_click",
      chapter: "sett",
      page: "setMain",
      block: "info",
      element: "email",
    },
  },
  "MySettings:DeleteAccount:Click": {
    ya: {
      eventName: "sett_setMain_deleteAcc_click",
      chapter: "sett",
      page: "setMain",
      element: "deleteAcc",
    },
  },
};
