import type { IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "LandingPage:Main:View": {
    ya: {
      block: "mainBanner",
      eventName: "landPWA_mainBanner_view",
      page: "landPWA",
    },
  },
  "LandingPage:Main:Header:Click": {
    ya: {
      block: "header",
      eventName: "landPWA_header_click",
      page: "landPWA",
    },
  },
  "LandingPage:Main:Button:Click": {
    ya: {
      block: "mainBanner",
      eventName: "landPWA_mainBanner_button_click",
      element: "button",
      page: "landPWA",
    },
  },
  "LandingPage:Apps:Click": {
    ya: {
      block: "appLink",
      eventName: "landPWA_appLink_click",
      page: "landPWA",
    },
  },
  "LandingPage:Online:Cards:View": {
    ya: {
      block: "promoPWA1",
      eventName: "landPWA_promoPWA1_view",
      page: "landPWA",
    },
  },
  "LandingPage:Online:Cards:Auth:Click": {
    ya: {
      block: "promoPWA1",
      eventName: "landPWA_promoPWA1_auth_click",
      page: "landPWA",
      element: "auth",
    },
  },
  "LandingPage:Online:Benefits:View": {
    ya: {
      block: "promoPWA2",
      eventName: "landPWA_promoPWA2_view",
      page: "landPWA",
    },
  },
  "LandingPage:Tutorial:Tab:Click": {
    ya: {
      block: "pwaQuickInstall",
      element: "tab",
      eventName: "landPWA_pwaQuickInstall_tab_click",
      page: "landPWA",
    },
  },
  "LandingPage:Tutorial:Slider:View": {
    ya: {
      block: "pwaQuickInstall",
      element: "slider",
      eventName: "landPWA_pwaQuickInstall_slider_view",
      page: "landPWA",
    },
  },
};
