import { default as cookie_45policyWrqZjqr6gMMeta } from "/app/src/pages/cookie-policy.vue?macro=true";
import { default as delete_accountPkSpf8zeEUMeta } from "/app/src/pages/delete_account.vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as login3hUYZUGxmUMeta } from "/app/src/pages/login.vue?macro=true";
import { default as addXqO71e7NclMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/cards/add.vue?macro=true";
import { default as indexiDbEtL8l9qMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/cards/index.vue?macro=true";
import { default as _91couponId_93l7O1lvdf4SMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/coupons/[couponId].vue?macro=true";
import { default as indexVyHXKVAXriMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/coupons/index.vue?macro=true";
import { default as couponshw8qGrR3H4Meta } from "/app/node_modules/@magnit/layer-profile/pages/profile/coupons.vue?macro=true";
import { default as editWbLM5pZ82qMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/edit.vue?macro=true";
import { default as favorite_45categoriesanof3xyY51Meta } from "/app/node_modules/@magnit/layer-profile/pages/profile/favorite-categories.vue?macro=true";
import { default as favoritesiSo9R2oFe6Meta } from "/app/node_modules/@magnit/layer-profile/pages/profile/favorites.vue?macro=true";
import { default as incompleteSR1xfcWojyMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/incomplete.vue?macro=true";
import { default as indexdcFnGaHCfYMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/index.vue?macro=true";
import { default as mplus3YwjLozP13Meta } from "/app/node_modules/@magnit/layer-profile/pages/profile/mplus.vue?macro=true";
import { default as notificationbhFzPZAHzdMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/notification.vue?macro=true";
import { default as change1nNRjzgitUMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/payment-cards/change.vue?macro=true";
import { default as indexscCXfooxJJMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/payment-cards/index.vue?macro=true";
import { default as payment_45cardsiD5tRGqt4VMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/payment-cards.vue?macro=true";
import { default as changeb1Dzs87SiuMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/payment-methods/change.vue?macro=true";
import { default as deletei6DACBR2VQMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/payment-methods/delete.vue?macro=true";
import { default as payLNOzghMwz1Meta } from "/app/node_modules/@magnit/layer-profile/pages/profile/payment-methods/pay.vue?macro=true";
import { default as _91promoId_93RM9CN8NtBdMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/personal-promotions/[promoId].vue?macro=true";
import { default as indexKZoUJxWNcVMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/personal-promotions/index.vue?macro=true";
import { default as personal_45promotionsCKt0o6RAlxMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/personal-promotions.vue?macro=true";
import { default as stickersmf5SAHgvKdMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/stickers.vue?macro=true";
import { default as supportkbEu700BPFMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/support.vue?macro=true";
import { default as _91id_93PP5aTtrhGdMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/transactions/[id].vue?macro=true";
import { default as indexSHIoNtf3JrMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/transactions/index.vue?macro=true";
import { default as transactionsI6LFpt076xMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile/transactions.vue?macro=true";
import { default as profile2DyFXs3DBAMeta } from "/app/node_modules/@magnit/layer-profile/pages/profile.vue?macro=true";
import { default as pushtokennSUtf65U9RMeta } from "/app/src/pages/pushtoken.vue?macro=true";
import { default as qr4xFs4J9BZ6Meta } from "/app/src/pages/qr.vue?macro=true";
import { default as indexNbUvxHm6A3Meta } from "/app/src/pages/registration/index.vue?macro=true";
import { default as subscriptionML5heDAjOdMeta } from "/app/src/pages/subscription.vue?macro=true";
import { default as todayfuO7Y3P8Z2Meta } from "/app/src/pages/today.vue?macro=true";
export default [
  {
    name: "cookie-policy",
    path: "/cookie-policy",
    component: () => import("/app/src/pages/cookie-policy.vue")
  },
  {
    name: "delete_account",
    path: "/delete_account",
    component: () => import("/app/src/pages/delete_account.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/src/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login3hUYZUGxmUMeta || {},
    component: () => import("/app/src/pages/login.vue")
  },
  {
    name: profile2DyFXs3DBAMeta?.name,
    path: "/profile",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile.vue"),
    children: [
  {
    name: "profile-cards-add",
    path: "cards/add",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/cards/add.vue")
  },
  {
    name: "profile-cards",
    path: "cards",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/cards/index.vue")
  },
  {
    name: couponshw8qGrR3H4Meta?.name,
    path: "coupons",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/coupons.vue"),
    children: [
  {
    name: "profile-coupons-couponId",
    path: ":couponId()",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/coupons/[couponId].vue")
  },
  {
    name: "profile-coupons",
    path: "",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/coupons/index.vue")
  }
]
  },
  {
    name: "profile-edit",
    path: "edit",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/edit.vue")
  },
  {
    name: "profile-favorite-categories",
    path: "favorite-categories",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/favorite-categories.vue")
  },
  {
    name: "profile-favorites",
    path: "favorites",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/favorites.vue")
  },
  {
    name: "profile-incomplete",
    path: "incomplete",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/incomplete.vue")
  },
  {
    name: "profile",
    path: "",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/index.vue")
  },
  {
    name: "profile-mplus",
    path: "mplus",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/mplus.vue")
  },
  {
    name: "profile-notification",
    path: "notification",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/notification.vue")
  },
  {
    name: payment_45cardsiD5tRGqt4VMeta?.name,
    path: "payment-cards",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/payment-cards.vue"),
    children: [
  {
    name: "profile-payment-cards-change",
    path: "change",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/payment-cards/change.vue")
  },
  {
    name: "profile-payment-cards",
    path: "",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/payment-cards/index.vue")
  }
]
  },
  {
    name: "profile-payment-methods-change",
    path: "payment-methods/change",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/payment-methods/change.vue")
  },
  {
    name: "profile-payment-methods-delete",
    path: "payment-methods/delete",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/payment-methods/delete.vue")
  },
  {
    name: "profile-payment-methods-pay",
    path: "payment-methods/pay",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/payment-methods/pay.vue")
  },
  {
    name: personal_45promotionsCKt0o6RAlxMeta?.name,
    path: "personal-promotions",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/personal-promotions.vue"),
    children: [
  {
    name: "profile-personal-promotions-promoId",
    path: ":promoId()",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/personal-promotions/[promoId].vue")
  },
  {
    name: "profile-personal-promotions",
    path: "",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/personal-promotions/index.vue")
  }
]
  },
  {
    name: "profile-stickers",
    path: "stickers",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/stickers.vue")
  },
  {
    name: "profile-support",
    path: "support",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/support.vue")
  },
  {
    name: transactionsI6LFpt076xMeta?.name,
    path: "transactions",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/transactions.vue"),
    children: [
  {
    name: "profile-transactions-id",
    path: ":id()",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/transactions/[id].vue")
  },
  {
    name: "profile-transactions",
    path: "",
    component: () => import("/app/node_modules/@magnit/layer-profile/pages/profile/transactions/index.vue")
  }
]
  }
]
  },
  {
    name: "pushtoken",
    path: "/pushtoken",
    component: () => import("/app/src/pages/pushtoken.vue")
  },
  {
    name: "qr",
    path: "/qr",
    component: () => import("/app/src/pages/qr.vue")
  },
  {
    name: "registration",
    path: "/registration",
    component: () => import("/app/src/pages/registration/index.vue")
  },
  {
    name: "subscription",
    path: "/subscription",
    meta: subscriptionML5heDAjOdMeta || {},
    component: () => import("/app/src/pages/subscription.vue")
  },
  {
    name: "today",
    path: "/today",
    component: () => import("/app/src/pages/today.vue")
  }
]