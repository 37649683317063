import type { IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "Subscription:View": {
    ya: {
      eventName: "magnit_plusUp_view",
      chapter: "magnit",
      page: "plusUp",
      status: "",
      nextPurchaseDate: "",
    },
  },
  "Subscription:Subscribe:View": {
    ya: {
      eventName: "magnit_plusUp_subscribe_view",
      chapter: "magnit",
      page: "plusUp",
      element: "subscribe",
      status: "",
      nextPurchaseDate: "",
    },
  },
  "Subscription:Subscribe:Click": {
    ya: {
      eventName: "magnit_plusUp_subscribe_click",
      chapter: "magnit",
      page: "plusUp",
      element: "subscribe",
      status: "",
      nextPurchaseDate: "",
    },
  },
  "Subscription:GoApp:View": {
    ya: {
      eventName: "magnit_plusUp_goApp_view",
      chapter: "magnit",
      page: "plusUp",
      element: "goApp",
    },
  },
  "Subscription:GoApp:Click": {
    ya: {
      eventName: "magnit_plusUp_goApp_click",
      chapter: "magnit",
      page: "plusUp",
      element: "goApp",
    },
  },

  "Subscription:PlusUp:Bonus:View": {
    ya: {
      eventName: "magnit_plusUp_bonus_view",
      chapter: "magnit",
      page: "plusUp",
      block: "bonus",
      action: "view",
      status: "",
      nextPurchaseDate: "",
    },
  },
  "Subscription:PlusUp:Bonus:Item:View": {
    ya: {
      eventName: "magnit_plusUp_bonus_item_view",
      chapter: "magnit",
      page: "plusUp",
      block: "bonus",
      element: "item",
      action: "view",
      name: "",
      status: "",
      nextPurchaseDate: "",
    },
  },
  "Subscription:PlusUp:Bonus:Item:Click": {
    ya: {
      eventName: "magnit_plusUp_bonus_item_click",
      chapter: "magnit",
      page: "plusUp",
      block: "bonus",
      element: "item",
      action: "click",
      name: "",
      status: "",
      nextPurchaseDate: "",
    },
  },
  "Subscription:PlusUp:PopUp:View": {
    ya: {
      eventName: "magnit_plusUp_popUp_view",
      chapter: "magnit",
      page: "plusUp",
      block: "popUp",
      action: "view",
      name: "",
    },
  },
  "Subscription:PlusUp:PopUp:Button:Click": {
    ya: {
      eventName: "magnit_plusUp_popUp_button_click",
      chapter: "magnit",
      page: "plusUp",
      block: "popUp",
      element: "button",
      action: "click",
      name: "",
    },
  },
};
