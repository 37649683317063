import type { IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "LoginPage:View": {
    ya: {
      eventName: "auth_onboardingFirst_view",
      chapter: "auth",
      page: "onboardingFirst",
    },
  },
  "LoginModal:Opener:Click": {
    ya: {
      eventName: "auth_onboardingFirst_continue_click",
      chapter: "auth",
      page: "onboardingFirst",
      element: "continue",
    },
  },
  "Onboarding:View": {
    ya: {
      eventName: "onboarding_slider_view",
      chapter: "onboarding",
      page: "slider",
    },
  },
  "Onboarding:Install:Click": {
    ya: {
      eventName: "onboarding_slider_addButton_click",
      chapter: "onboarding",
      page: "slider",
      element: "addButton",
      title: "addIcon",
    },
  },
  "Onboarding:Login:Click": {
    ya: {
      eventName: "onboarding_slider_auth_click",
      chapter: "onboarding",
      page: "slider",
      element: "auth",
    },
  },
  "Onboarding:Install:Finish:View": {
    ya: {
      eventName: "onboarding_slider_installFinish_view",
      chapter: "onboarding",
      page: "slider",
      block: "installFinish",
    },
  },
  "Onboarding:Install:Prompt:View": {
    ya: {
      eventName: "onboarding_slider_installPwa_view",
      chapter: "onboarding",
      page: "slider",
      block: "installPwa",
    },
  },
  "Onboarding:Install:Prompt:Ok": {
    ya: {
      eventName: "onboarding_slider_installPwa_ok_click",
      chapter: "onboarding",
      page: "slider",
      block: "installPwa",
      element: "ok",
    },
  },
  "Onboarding:Install:Prompt:Cancel": {
    ya: {
      eventName: "onboarding_slider_installPwa_cancel_click",
      chapter: "onboarding",
      page: "slider",
      block: "installPwa",
      element: "cancel",
    },
  },
};
