import type { IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "MyCardPage:View": {
    ya: {
      eventName: "card_cardMain_view",
      chapter: "card",
      page: "cardMain",
    },
  },
  "MyCardPage:Qr:View": {
    ya: {
      eventName: "card_cardMain_qr_view",
      chapter: "card",
      page: "cardMain",
      block: "qr",
    },
  },
  "MyCardPage:Card:View": {
    ya: {
      eventName: "card_cardMain_numCard_view",
      chapter: "card",
      page: "cardMain",
      block: "numCard",
    },
  },
  "MyCardPage:Card:Copy:Click": {
    ya: {
      eventName: "card_cardMain_numCard_copy_click",
      chapter: "card",
      page: "cardMain",
      block: "numCard",
      element: "copy",
    },
  },
  "MyCardPage:Card:Copy:View": {
    ya: {
      eventName: "card_cardMain_numCard_ok_view",
      chapter: "card",
      page: "cardMain",
      block: "numCard",
      element: "ok",
    },
  },
  "MyCardPage:Wallet:View": {
    ya: {
      eventName: "card_cardMain_wallet_view",
      chapter: "card",
      page: "cardMain",
      block: "wallet",
    },
  },
  "MyCardPage:Wallet:Click": {
    ya: {
      eventName: "card_cardMain_wallet_click",
      chapter: "card",
      page: "cardMain",
      block: "wallet",
    },
  },
  "MyCardPage:LoadError:View": {
    ya: {
      eventName: "card_cardMain_cardLoadErr_view",
      chapter: "card",
      page: "cardMain",
      block: "cardLoadErr",
    },
  },
  "MyCardPage:LoadError:Refresh": {
    ya: {
      eventName: "card_cardMain_cardLoadErr_refresh_click",
      chapter: "card",
      page: "cardMain",
      block: "cardLoadErr",
      element: "refresh",
    },
  },
  "MyCardPage:LoadError:Back": {
    ya: {
      eventName: "card_cardMain_cardLoadErr_back_click",
      chapter: "card",
      page: "cardMain",
      block: "cardLoadErr",
      element: "back",
    },
  },
};
